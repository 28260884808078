.login-card {
  display: flex;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
  margin-top: 100px;
}

.by-alleviate {
  display: flex;
  justify-content: center;
  align-items: center;
}