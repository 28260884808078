.app {
  display: flex;
  padding: 1rem;
  gap: 2rem;
}

.app__body {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  flex-grow: 1;
}

.card {
  background-color: #fff;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 1rem;
}

.card-filters {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.card__header {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #344767;
}

h2 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #344767;
}

hr {
  background-color: #000;
  border-radius: 1rem;
  opacity: 0.125;
}

.section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;

  div {
    flex: 1 0 1;

    @media (max-width: 1024px) {
      flex-grow: 1;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.centered {
  align-items: center;
}

.form-select {
  width: auto;
}

td {
  padding: 0 0.1rem;
}

.code {
  font-family: monospace;
  font-size: 1rem;
  background-color: #f5f5f5;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  width: fit-content;
}
