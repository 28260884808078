.nav-link-item {
  display: flex;
  align-items: center;
  color: #344767;
  padding: 0.675rem;
  text-decoration: none;

  & div {
    & svg {
      fill: rgba(52, 71, 103, 0.9);
    }
  }
}

.navbar-item__logo-container {
  background-image: linear-gradient(315deg, #fff, #fff);
  width: fit-content;
  margin-right: 10px;
  padding: 8px;
  border-radius: 0.5rem;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}

.nav-link-item--active {
  font-weight: bold;
  background-color: #fff;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;

  & .navbar-item__logo-container {
    background-image: linear-gradient(225deg, #db5353e8, #ff0000d1);

    & svg {
      fill: #fff;
    }
  }
}
