.alert-message-container {
  z-index: 100;
}

.alert-message--success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

// .alert-message--warning {
//   color: #664d03;
//   background-color: #fff3cd;
//   border-color: #ffecb5;
// }

// .alert-message--danger {
//   color: #842029;
//   background-color: #f8d7da;
//   border-color: #f5c2c7;
// }
